const text_truncate = ({ str, length, ending }: any) => {
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = "...";
    }
    if (str && str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};
export default text_truncate;
